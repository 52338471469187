var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"src":require('@/assets/icons/location.svg'),"max-width":"104px","alt":_vm.$t(((_vm.contract_savings_geolocation_view_model.i18n_namespace) + ".alt_img"))}})],1),_c('h3',{staticClass:"text-center primary--text ma-5"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_geolocation_view_model.i18n_namespace) + ".title")))+" ")]),_c('p',{class:{
      'text-font-secondary primary--text': true,
      'text-center': !_vm.contract_savings_geolocation_view_model.small_screen
    }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_geolocation_view_model.i18n_namespace) + ".text_1")))+" ")]),_c('p',{class:{
      'text-font-secondary primary--text': true,
      'text-center': !_vm.contract_savings_geolocation_view_model.small_screen
    }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_geolocation_view_model.i18n_namespace) + ".text_2")))+" ")]),_c('p',{class:{
      'text-font-secondary sky-blue--text ma-auto text-center': true,
      'text-center': !_vm.contract_savings_geolocation_view_model.small_screen
    }},[_c('a',{staticClass:"sky-blue--text",attrs:{"href":"https://www.abm.org.mx/sala-de-prensa/historico/Comunicado_17_03_2021.pdf","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_geolocation_view_model.i18n_namespace) + ".link")))+" ")])]),_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('small',{staticClass:"inactive-blue--text mb-10 d-block"},[_vm._v(" "+_vm._s(_vm.$t( ((_vm.contract_savings_geolocation_view_model.i18n_savings_namespace) + ".kuspit.disclaimer") ))+" ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"accent","large":"","width":"100%","height":"3.5em","disabled":_vm.contract_savings_geolocation_view_model.is_loading},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_geolocation_view_model.i18n_namespace) + ".accept")))+" ")])],1),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"secondary","large":"","width":"100%","height":"3.5em","disabled":_vm.contract_savings_geolocation_view_model.is_loading},on:{"click":function($event){return _vm.$emit('prevStep')}}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_geolocation_view_model.i18n_namespace) + ".back")))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }